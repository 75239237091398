.tags-container {
    width: 100%; /* Take full width of parent container */
    padding: 0.5rem;
}

.tags-flex {
    display: flex;
    flex-wrap: wrap; /* Enables wrapping */
    gap: 0.5rem; /* Adds spacing between items */
    list-style: none; /* Removes default list styling (optional) */
}

/* Restore Bootstrap styles for the "active" class */
.list-group-item.active {
    background-color: #0d6efd; /* Bootstrap primary color */
    color: white;
    border-color: #0d6efd;
}

/* Add spacing and visual adjustments */
.list-group-item {
    /* cursor: pointer;
    padding: 0.5rem 0.5rem;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    text-align: center; */

    align-items: center;
    background: #85A3BF;
    border-radius: 2px;
    color: white;
    display: flex;
    font-weight: 300;
    list-style: none;
    margin-bottom: 1px;
    margin-right: 1px;
    padding: 0px 10px;
    cursor: pointer;
}
